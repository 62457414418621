// eslint-disable-next-line no-restricted-imports -- Exempt the import of i18next
import i18next, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';

export const translationsNs = 'translation';

const resources: Record<string, Resource> = {
  en: {
    [translationsNs]: en,
  },
};

const defaultLanguage = 'en';
const fallbackLanguage = 'en';

const supportedLanguages = Object.keys(resources);

// eslint-disable-next-line @typescript-eslint/no-floating-promises, import/no-named-as-default-member -- ignore i18next init
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: process.env.NODE_ENV === 'development',
    lng: defaultLanguage,
    fallbackLng: fallbackLanguage,
    pluralSeparator: '-',
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18next;
